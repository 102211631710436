// base icon class definition
// -------------------------
.#{$fa-css-prefix} {
    font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
    font-weight: var(--#{$fa-css-prefix}-style, #{$fa-style});
}

.#{$fa-css-prefix},
.fas,
.#{$fa-css-prefix}-solid,
.far,
.#{$fa-css-prefix}-regular,
.fal,
.#{$fa-css-prefix}-light,
.fat,
.#{$fa-css-prefix}-thin,
.fad,
.#{$fa-css-prefix}-duotone,
.fab,
.#{$fa-css-prefix}-brands {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--#{$fa-css-prefix}-display, #{$fa-display});
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    font-size: 20px;
}

%fa-icon {
    @include fa-icon;
}