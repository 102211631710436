@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?uwhfi2');
    src: url('fonts/icomoon.eot?uwhfi2#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?uwhfi2') format('truetype'), url('fonts/icomoon.woff?uwhfi2') format('woff'), url('fonts/icomoon.svg?uwhfi2#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/new_icomoon.eot?wgka61');
    src: url('fonts/new_icomoon.eot?wgka61#iefix') format('embedded-opentype'),
        url('fonts/new_icomoon.ttf?wgka61') format('truetype'),
        url('fonts/new_icomoon.woff?wgka61') format('woff'),
        url('fonts/new_icomoon.svg?wgka61#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-stripe1:before {
    content: "\e800";
}

.icon-dpo-instant:before {
    content: "\e802";
}

.icon-stripe:before {
    content: "\e801";
}

.icon-contact-vendor:before {
    content: "\e91a";
}

.icon-bar-chart:before {
    content: "\e91b";
}

.icon-compaign:before {
    content: "\e91c";
}

.icon-export:before {
    content: "\e91d";
}

.icon-add-contact:before {
    content: "\e900";
}

.icon-add-people:before {
    content: "\e901";
}

.icon-airtel:before {
    content: "\e902";
}

.icon-airtel-tz-instant:before {
    content: "\e902";
}

.icon-airtel-mw-instant:before {
    content: "\e902";
}

.icon-bank-transfer:before {
    content: "\e903";
}

.icon-nmb-tz-instant:before {
    content: "\e903";
}

.icon-cash:before {
    content: "\e904";
}

.icon-check:before {
    content: "\e905";
}

.icon-cheque:before {
    content: "\e906";
}

.icon-create-group:before {
    content: "\e907";
}

.icon-crm-sms:before {
    content: "\e908";
}

.icon-custom-reminder:before {
    content: "\e909";
}

.icon-delete:before {
    content: "\e90a";
}

.icon-disable:before {
    content: "\e90b";
}

.icon-edit-icon:before {
    content: "\e90c";
}

.icon-edit-profile:before {
    content: "\e90d";
}

.icon-file-sms:before {
    content: "\e90e";
}

.icon-group-sms:before {
    content: "\e90f";
}

.icon-m-pesa:before {
    content: "\e910";
}

.icon-notification:before {
    content: "\e911";
}

.icon-package:before {
    content: "\e912";
}

.icon-quick-sms:before {
    content: "\e913";
}

.icon-schedule-date:before {
    content: "\e914";
}

.icon-search:before {
    content: "\e915";
}

.icon-successful:before {
    content: "\e916";
}

.icon-target-segments:before {
    content: "\e917";
}

.icon-tigo-pesa:before {
    content: "\e918";
}

.icon-tigo-tz-instant:before {
    content: "\e918";
}

.icon-upload:before {
    content: "\e919";
}