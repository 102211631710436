.dashboard {
  padding: 1rem;
}

.dashboard > :not(:last-child) {
  margin-bottom: 1rem;
}

.dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashboard-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media all and (max-width: 480px) {
    flex-direction: column;
  }
}

.dashboard-cards > * {
  flex: 1;
}

.dashboard-cards > *:not(:last-child) {
  margin-right: 0.5rem;

  @media all and (max-width: 480px) {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
}

.dashboard-reports {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 35.714rem;
  max-height: 35.714rem;
  height: 35.714rem;

  @media all and (max-width: 960px) {
    flex-direction: column;
  }
}

.dashboard-reports > * {
  flex: 1;
}

.dashboard-reports > :not(:last-child) {
  margin-right: 0.5rem;

  @media all and (max-width: 960px) {
    margin-bottom: 0.5rem;
    margin-right: 0rem;
  }
}

.dashboard-cards-reports {
  flex: 1;
}

.dashboard-iframe {
  display: flex;
  border: none;
  width: 100%;
  height: 100%;
  flex: 1;
}

.dashboard-loader {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.dashboard-checklist {
  display: flex;
  flex-direction: column;
}

.onbaording-checklist {
  border-bottom: 1px solid #e2e2ee;
  padding: 1rem !important;

  &:hover {
    background-color: #f6f6f6 !important;
  }
}

.blink {
  animation: blinking 1s infinite !important;
  font-weight: bold;
}

@keyframes blinking {
  // 0% {
  //     color: #f5bc5a;
  // }
  // 25% {
  //     color: #fabb4f;
  // }
  // 50% {
  //     color: #f3a929;

  // }
  // 100% {
  //     color: #f09c0b;
  // }
  0% {
    color: #34c8d3;
  }

  25% {
    color: #35b7c0;
  }

  50% {
    color: #33b1ba;
  }

  100% {
    color: #249097;
  }
}

.billing-form-content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.h2-broadcast-reports {
  width: 100%;
  height: 54px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.radio-input-broadcast-reports {
  width: 20px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 1%;
}

.div-filter-reports {
  border: 1px solid #aeafaf;
  border-radius: 5px;
}

.custon-filter-button {
  background-color: #33B1BA;
  color: white;
  border-color: white;
  width: 160px;
}

.bmcard-reports {
  width: 342px;
  height: 171px;
  background: #FFFFFF;
  border: 1px solid #AFAFAF;
  border-radius: 3.5px;
  flex-direction: none;
  order: 0;
  flex-grow: 1;
}
.margin-bottom-reports{
   margin-right: 15px;
}
.header-broadcast-reports {
  width: 233px;
  height: 27px;
  padding-left: 16px;
  padding-top: -26px;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #000000;
  text-align: left;
}

.body-broadcast-reports {
  width: 58px;
  height: 54px;
  left: 16px;
  top: 51px;
  font-weight: 500 !important;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  padding-left: 16px;
  color: #000000;
}

.reports-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0px; 
}

.reports-icons {
  width: 36px !important;
  height: 36px !important;
  margin-right: 1rem; /* Optional: Add spacing between the text and icon */
}

.line-inside-card {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  width: 100%;
  border-top: 1px solid grey;
}

.check-broadcast-reports {
  margin-left: 15%;
  margin-top: 2%;
  color: #fd7e14;
}
.main-div-broadcast-reports {
  display: flex;
  flex-direction: row;
  height: 150px;
}

.overview-header-reports {
  height: 54px;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.input-radio-overview {
  width: 20px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-top: 1%;
}


.header-reports-text {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 452px;
  color: #575757 !important;
  height: 21px;
  gap: 0;
}
.header-reports-text-subheading {
  text-decoration: underline;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  color: #575757 !important;
}
.header-reports-video {
  font-family: 'Poppins', sans-serif !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: left;
}

.flex-gap{
  gap: 1rem;
}

.custom-template-checkbox {
  transform: scale(0.8); 
}