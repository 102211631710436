.react-datepicker-wrapper {
    width: 100%;
}

.table-wrapper {
    position: relative;
    overflow: auto;
    border: 0px;
    width: 100%;

    &::-webkit-scrollbar-track {
        width: 8px;
        background-color: #edf0f5;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 16px;
        height: 8px;
        background-color: #a7a8ac;
    }

    &.loading {
        opacity: 0.5;

        &>div {
            display: block;
            width: 100%;
            height: 100%;
            z-index: 1000;
            position: relative;
            top: 10px;
            left: 50%;
        }
    }
}

.DataTable {
    .sortable {
        cursor: pointer;

        span {
            &::before {
                content: '\2191';
                opacity: 0.4;
            }

            &::after {
                content: '\2193';
                opacity: 0.4;
            }
        }

        .asc {
            &::before {
                opacity: 1;
            }
        }

        .desc {
            &::after {
                opacity: 1;
            }
        }
    }
}

.m-t-l {
    margin-top: 40px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-l {
    margin-bottom: 40px;
}

.active-payment-method {
    background-color: #33b1ba;
    color: #fff;
}

.active-beem-color {
    color: #f3a929;
}

.beem-help-form {
    background-color: #f1f1f1;
}

_override.scss .beem-affiliate-modal {
    background-color: #f1f1f1;
}

.accordion-sub-menu {
    margin-left: 0.5rem;
}

.accordion-body {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.active-payment-method {
    background-color: #33b1ba;
    color: #fff;
}

.beem-help-form {
    background-color: #f1f1f1;
}

.beem-color {
    color: #33b1ba;
}

.reference {
    font-size: 20px;
}

.w-b {
    font-weight: bold;
}

.btn:focus,
.btn-primary:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.iframe {
    width: 100%;
    height: 100v h;
}

.reference {
    color: #33b1ba;
    font-size: 20px;
}

.w-b {
    font-weight: bold;
}

.btn:focus,
.btn-primary:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.custom-modal-style {
    width: 897px;
    height: 802px;
}

.sandbox-container {
    color: #d3a85e;
    background-color: #616569;
    max-width: fit-content;
    border: #616569 solid;
    border-radius: 10px;
    padding: 5px;
}


/* The device with borders */

.smartphone {
    position: relative;
    width: 300px;
    height: 550px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
}


/* The horizontal line on the top of the device */

.smartphone:before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #a7a8ac;
    border-radius: 10px;
}


/* The circle on the bottom of the device */

.smartphone:after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #a7a8ac;
    border-radius: 50%;
}


/* The screen (or content) of the device */

.smartphone .content {
    width: 360px;
    height: 400px;
    background: white;
}

.checkout-sandbox {
    font-size: 12px;
}

.block-example {
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
    border-radius: 0%;
    border-color: #33b1ba;
    display: inline-block;
}

.sandbox-color {
    color: #33b1ba;
}

.try-it-out-feedback {
    display: flex;
    flex-direction: column;
    border: 1px solid #e2e2e2;
    padding: 1rem;
    margin-bottom: 0.5rem;

    >*:not(:last-child) {
        margin-bottom: 0.5rem;
    }
}

.try-it-out {
    display: flex;
    width: 100%;

    >* {
        flex-direction: column;
        width: 50%;
    }

    >*:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.api-table {

    border: #d1d8f5 1px solid;

    th,
    td {
        @extend .px-3;
    }

    thead {
        th {
            @extend .py-3;
            font-size: 14px;
            color: #686868;
            border: #d1d8f5 1px solid;
        }

        &+td {
            border: #d1d8f5 1px solid;
        }
    }

    tbody {
        td {
            font-size: 12px;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border: #d1d8f5 1px solid;
        }
    }

    tfoot {
        th {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border: #d1d8f5 1px solid;
        }
    }
}